.title-1 {
    display: inline-block;
    color: #000;
}

.my-3 {
    border-radius: 0 2.5em 0 2.5em;
}

.label-1 {
    font-size: 26px;
    text-align: left;
    display: block;
    font-style: italic;
    margin: 0;
    padding: 0;
    padding-bottom: 20px;
}

.dropdown-container {
    display: flex;
    justify-content: center;
    /* margin: 20px 0; */
}

.dropdown_icon {
    width: 35px;
    padding-left: 10px;
}

#dropdown-basic-button {
    background-color: #3D52A0; /* Change background color to red */
    border-color: #3D52A0; /* Change border color to red */
    width: 400px;
    font-size: 20px;
}

.custom-modal {
    max-height: 720px; /* Set a maximum height for the dropdown menu */
}


.custom-modal-header {
    position: sticky;
    top: 0;
    z-index: 1000; /* Ensure it stays on top of other content */
    background-color: white; /* Match the modal background */
    border-bottom: 1px solid #dee2e6; /* Optional: Add a border for better separation */
}
  
.custom-modal-body {
    max-height: calc(100vh - 150px); /* Adjust according to your needs */
    overflow-y: auto; /* Enable vertical scrolling */
    padding-top: 1rem; /* Ensure the body content is not hidden under the header */
}

.modal-body-profile {
    color: #000;
}

.modal-body-profile:hover {
    background-color: #adb2c0;
}

.dropdown-item {
    font-size: 18px;
    font-weight: 600;
    width: 500px;
}

.dropdown-item:hover,
.dropdown-item:focus {
  background-color: #ADBBDA; /* Change dropdown item background color on hover and focus */
}

.custom-dropdown-button {
    font-family: 'Garamond';
}

.clickable-image {
    margin-left: 5px;
    width: 25px;
    height: 25px;
    vertical-align: middle;
}

.duration_logo {
    display: inline-block;
    vertical-align: middle;
    width: 8%;
    mix-blend-mode: multiply;
}

.duration {
    display: inline-block;
    vertical-align: -5px;
    margin-left: 5px;
    font-size: 22px;
    font-family: 'Garamond';
    font-weight: 600;
}

.price {
    display: inline-block;
    vertical-align: -10px;
    margin-left: 40px;
    font-family: 'Garamond';
}

.recommended{
    display: inline-block;
    vertical-align: -10px;
    font-size: 16px;
    color: red;
    font-style: italic;
    vertical-align: -5px;
    margin-left: 40px;
    font-weight: 500;
    animation: blink 0.5s infinite;
}

@keyframes blink {
    0% { opacity: 1; }
    50% { opacity: 0; }
    100% { opacity: 1; }
}

.actual-price{
    display: inline-block;
    vertical-align: -5px;
    margin-left: 40px;
    opacity: 0.7;
    text-decoration: line-through;
    text-decoration-color: rgb(158, 3, 3);
    font-family: 'Garamond';
}

.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: right;
    align-items: center;
    z-index: 1000; /* Ensure it is above other content */
}

.popup-content {
    position: relative;
    background-image: linear-gradient(#ADBBDA, #EDE8F5);
    width: 85%;
    height: 100%;
    display: flex;
    border-radius: 10px;
    box-shadow: 0 5px 15px rgba(251, 180, 1, 0.3);
    animation: slideInFromRight 0.8s ease-out; /* Slide-in animation */
}

.popup-left {
    width: 50%; /* Half of the popup content */
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid #ccc; /* Separate with a border */
}

.new-image {
    max-width: 100%;
    height: 110%;
    opacity: 0.7;
}

.popup-right {
    width: 50%; /* Half of the popup content */
    padding: 20px;
    text-align:start;
    overflow-y: auto; /* Allow vertical scrolling if content overflows */
    max-height: 100%;
}

.popup-content input[type="radio"] {
    transform: scale(1.5);
    margin: 10px 5px;
    vertical-align: -5px;
}

.close {
    position: absolute;
    top: 10px;
    right: 20px;
    font-size: 30px;
    cursor: pointer;
    color: #000; /* Better visibility */
}

@keyframes slideInFromRight {
    from {
        transform: translateX(100%); /* Start from right side */
    }
    to {
        transform: translateX(0); /* Slide to original position */
    }
}

.form_first_name, .form_last_name, .form_email, .form_phone, .form_msg {
    display: inline-block;
    width: 45%; /* Adjust width to fit within container */
    margin-bottom: 10px; /* Add margin for spacing */
    box-sizing: border-box;
}

.form_last_name, .form_phone{
    margin-left: 40px;
}

.form_first_name label, .form_last_name label, .form_email label, .form_phone label, .form_msg label {
    font-weight: bold;
    font-size: 14px;
    text-align: left;
    display: block;
    font-family: 'Garamond';
}

.form_first_name input, .form_last_name input, .form_email input, .form_phone input, .form_msg textarea {
    width: 100%;
    padding: 10px; /* Add padding for better appearance */
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
    box-sizing: border-box;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.form_first_name input:focus, .form_last_name input:focus, .form_email input:focus, .form_phone input:focus, .form_msg textarea:focus {
    border-color: #c3a914;
    outline: none;
    box-shadow: 0 0 5px rgba(195, 169, 20, 0.5);
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.form_msg {
    width: 100%;
}

.form_msg textarea {
    resize: none;
    height: 150px;
    width: 100%;
    padding: 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
    box-sizing: border-box;
}

.form_submit {
    background-color: #3D52A0;
    border: none;
    color: white;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    width: 100%;
    max-width: 600px;
    margin: 20px auto;
    display: block;
}

.form_submit:hover {
    background-color: #a38412;
}

.form_submit:focus {
    outline: none;
    box-shadow: 0 0 5px rgba(195, 169, 20, 0.5);
}

.form_1, .form_2 {
    display: inline-block;

    text-align: left;
    width: 100%; /* Ensure form containers take full width */
    box-sizing: border-box;
}
.form_1{
    margin-top: 40px;
}
@media (max-width: 768px) {
    .title-1 {
        display: block;
    }
    .inline-container {
        display: flex;
    }
    .form_last_name, .form_phone{
        margin-left: 0;
    }
    .inline-container {
        float: left;
    }
    .popup-left {
        display: none;
    }

    .popup-right {
        width: 100%; /* Make the right section take up the full width */
    }
    
    .popup-content {
        width: 100%; /* Make the popup content take up full width */
    }
    .actual-price{
        margin-left: 10px;
    }

    .price {
        margin-left: 10px;
        vertical-align: -5px;
    }
    
    .recommended{
        font-size: 12px;
        vertical-align: 1px;
        margin-left: 15px;
    }
    
    .form_first_name, .form_last_name, .form_email, .form_phone, .form_msg {
        width: 100%; /* Full width for form fields */
        margin-right: 0; /* Remove right margin */
    }
    
    .form_msg label {
        margin-right: 0; /* Adjust label margin for mobile view */
    }
}

@media (max-width: 1024px) {
    .form_last_name, .form_phone{
        margin-left: 0;
    }
}