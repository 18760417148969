.slides-body {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  height: 100vh; /* Set the body to occupy the entire viewport height */
}

/* Style for the "Trusted By" header */
.trusted-by-header {
  font-size: 28px; /* Adjust the font size as needed */
  text-align: center; /* Center-align the text */
  color: #333; /* Text color */
  margin-bottom: 10px; /* Spacing between the header and logos */
  font-family: 'Garamond';
  font-weight: 600;
}

/* Service Image Slider styles */
.service-slider {
  /* Set the maximum height to 80% of the viewport height */
  overflow: hidden;
  position: relative;
  height: 80vh;
}

.service-image {
  max-width: 100%;
  height: 80vh;
}

/* Add these styles to your CSS file */
.slider-item {
  position: relative;
  overflow: hidden;
}

/* Client Logo Slider styles */
.client-logo-slider {
  overflow: hidden;
  position: relative;
  background-color: #ADBBDA;
  background-size: cover;
  height: 20vh;
}

/* Add this to your stylesheet */
.client-logo {
  max-width: 100%;
  height: 15vh;
  margin: 0; /* Remove padding */
  display: block;
  padding: 30px;
}

.client-logo-container {
  display: flex;
  animation: scroll 25s linear infinite;
}

/* Add a wrapper div for animation */
.logo-animation-wrapper {
  width: 100%; /* Set the width to 100% */
  display: flex;
  justify-content: flex-end; /* Align logos to the right */
}

@keyframes scroll {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.legend-container {
  position: absolute;
  bottom: 10%;
  left: 50%;
  transform: translateX(-50%);
  width: 40%; /* Set the width to 40% */
  background-color: rgba(255, 255, 255, 0.3); /* Adjust the background transparency as needed */
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.slider-button {
  background-color: transparent;
  border: none;
  color: white;
  font-size: 16px;
  font-weight: bold; /* Add this line to make the text bold */
  cursor: pointer;
}

.slider-button:hover {
  background-color: rgba(0, 0, 0, 0.5); /* Adjust the hover transparency as needed */
  border-radius: 5px;
}

.carousel .hot-tot {
  margin-bottom: 1%;
}

/* Media query for screens with a maximum width of 768px (typical mobile devices) */
@media (max-width: 768px) {
  .slides-body {
    height: auto; /* Remove the fixed height for mobile screens */
  }

  .trusted-by-header {
    font-size: 20px; /* Adjust the font size as needed */
  }

  .client-logo-container {
    animation: scroll 10s linear infinite;
  }
  
  .service-slider,
  .service-image {
    height: 70vh;
  }

  .client-logo-slider {
    height: 30vh;
  }
}
