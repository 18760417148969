/* Add styles for the centered image */
.centered-image {
  max-width: 100%; /* Adjust the maximum width as needed */
  height: auto; /* This will maintain the aspect ratio of the image */
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto; /* Center horizontally within the container */
}

.about-us {
  margin: auto;
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 5%;
}

h1 {
  font-size: 36px;
  margin-top: 0;
}

p {
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  font-size: 20px;
  text-align: justify;
}

@media (max-width: 768px) {
  .about-us {
    width: 90%;
    height: 100svh;
    padding: 10px;
  }
  
  h1 {
    font-size: 28px;
  }
  
  p {
    font-size: 16px;
  }

  .centered-image {
    padding: 15px;
  }
}
