.contact-logo{
    position: fixed;
    bottom: 5px;
    right: -180px;
}

.contact-logo img{
    width: 50%;
    display: block;
}

.contact-logo a {
    position: relative;
    display: inline-block;
    text-decoration: none; /* Remove underline from the link */
}

.hover-text {
    position: absolute;
    bottom: 20%;
    left: 25%;
    transform: translate(-50%, 50%);
    color: rgb(0, 0, 0);
    background-color: rgb(255, 255, 255);
    padding: 5px 5px;
    border-radius: 5px;
    border: 3px solid #8697C4;
    display: none;
    pointer-events: none;
}

.hover-text::after {
    content: "";
    position: absolute;
    bottom: 35px;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid rgb(255, 255, 255);
    border-bottom-color: #8697C4; /* Arrow color */
}

.contact-logo a:hover .hover-text {
    display: block;
}

@media screen and (max-width: 768px){
    .contact-logo img{
        width: 40%;
    }

    .contact-logo{
        bottom: 5px;
        right: -210px;
    }

    .hover-text {
        bottom: 15%;
        left: 20%;
    }
}