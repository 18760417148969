form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  max-width: 600px;
  width: 100%;
}

label {
  margin-top: 1rem;
  font-size: 1.1rem;
  font-weight: bold;
  color: #333;
}

input[type="text"],
input[type="email"],
textarea {
  width: 100%;
  padding: 0.5rem;
  margin-top: 0.5rem;
  border-radius: 4px;
  border: 2px solid #ccc;
  font-size: 1.1rem;
}

textarea {
  height: 150px;
}

input[type="submit"] {
  background-color: #0066cc;
  color: white;
  border: none;
  padding: 0.5rem 2rem;
  margin-top: 1rem;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1.1rem;
}

input[type="submit"]:hover {
  background-color: #0052a3;
}

/* Media queries for responsive design */
@media (max-width: 768px) {
  form {
    max-width: 90%;
  }

  input[type="submit"] {
    font-size: 1rem;
  }

  .content {
    flex-direction: column-reverse;
  }
  .illustration {
    display: none;
  }
}

@media (max-width: 480px) {
  label {
    font-size: 1rem;
  }

  input[type="text"],
  input[type="email"],
  textarea {
    font-size: 1rem;
  }

  input[type="submit"] {
    font-size: 0.9rem;
  }
}
