/* Additional styles for service details */
.service-detail {
  position: relative; /* Make the container relative for absolute positioning of the decorative element */
  width: 100%;
  max-width: 1500px;
  margin: 0 auto;
  padding: 20px;
  box-sizing: border-box;
}

.service-title {
  font-size: 2rem;
  color: #3D52A0;
  font-weight: bold;
  font-family: Copperplate Gothic;
  margin-bottom: 20px;
}

.service-details {
  font-size: 1.2rem;
  line-height: 1.5;
  margin-bottom: 30px;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.card-title {
  color: #3D52A0;
  font-family: Garamond;
  font-weight: 600;
  font-size: 24px;
}

.card-content {
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.tab-header-1 {
  font-family: Garamond;
  font-size: 18px;
  font-weight: 600;
}

.tab-header-2 {
  font-family: 'Garamond';
  font-size: 18px;
  font-weight: 600
}

.tab-content {
  margin-top: 20px;
  border: 1px solid #ccc;
  padding: 20px;
  border-radius: 0 0 5px 5px;
  background-color: #EDE8F5;;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.why-apnito {
  margin-top: 30px;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.why-apnito-title {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 20px;
}

.why-apnito-list {
  list-style: disc;
  padding-left: 20px;
  font-size: 1.2rem;
  line-height: 1.5;
  margin-bottom: 30px;
}

/* Media query adjustments for smaller screens */
@media only screen and (max-width: 767px) {
  .service-title {
    font-size: 1.5rem;
  }

  .service-details {
    font-size: 1rem;
    margin: 20px 0;
  }

  .why-apnito-title {
    font-size: 1.2rem;
  }

  .why-apnito-list {
    font-size: 1rem;
  }
}
