.header {
    font-weight: 600;
    font-size: 26px;
    color: #EDE8F5;
    text-decoration: none;
    font-family: 'Garamond';
}

.footer-desclaimer{
    font-size: 16px;
    text-align: center;
    color: #EDE8F5;
}

.text-white {
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    text-decoration: none;
}

.text-whiter{
    text-decoration: none;
}

.footer {
    background-color: rgb(2, 2, 38);
}