.menu-icon {
    font-size: 24px;
    cursor: pointer;
    display: none;
    color: #ffffff;
}

.transparent-bg {
    background: transparent;
  }
  
  .scrolled-bg {
    background-color: #8697C4; 
  }

.navbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    /* background-image: url('./assets/Screenshot from 2023-10-02 17-00-23.png'); */
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 100;
    font-family: 'Roboto', sans-serif;
    font-size:x-large;
}

.logo {
    border-radius: 0%; /* Set the border radius to make it circular */
}
    
.navbar-left {
    flex: 1;
}
    
.navbar-left img {
    height: 40px;
}
    
.navbar-right {
    flex: 2;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
    
.navbar-right a {
    color: #ffffff;
    text-decoration: none;
    font-size: 23px;
    margin: 0 10px;
    transition: all 0.3s ease-in-out;
    font-family: Copperplate Gothic;
    font-weight: 900;
}
    
.navbar-right a:hover {
    color: #EDE8F5;
}
    
.navbar-right a:active {
    transform: translateY(2px);
}
    
.menu-icon {
    font-size: 24px;
    cursor: pointer;
    display: none;
}
    
@media only screen and (max-width: 767px) {
    .navbar-right {
        position: fixed;
        top: 70px;
        left: 0;
        /* background-image: url('./assets/Screenshot from 2023-10-02 17-00-23.png'); */
        /* background-color: #8697C4; */
        background-image: linear-gradient(#8697C4, #989797);
        width: 100%;
        height: calc(100% - 70px);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        transform: translateX(-100%);
        transition: transform 0.3s ease-in-out;
        z-index: 99;
    }
    
    .navbar-right.active {
        transform: translateX(0);
    }
    
    .navbar-right a {
        font-size: 30px;
        margin: 20px 0;
    }
    
    .menu-icon {
        display: block;
    }
}

@media only screen and (max-width: 1100px){
    .navbar-right a {
        font-size: 20px; /* Adjust the font size as needed for 1000px screen */
      }
}