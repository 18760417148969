.JobRole {
  width: 100%;
  margin-bottom: 40px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  transition: box-shadow 0.3s ease-in-out;
  
}

.JobRole:hover {
  box-shadow: 0 0 5px #ccc;
}

.JobRole h2 {
  font-size: 24px;
  margin-bottom: 10px;
}

.JobRole p {
  font-size: 16px;
  margin-bottom: 20px;
}

.JobRole a {
  display: block;
  margin: 0 auto;
  width: 120px;
  padding: 10px;
  background-color: #0077b5;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  float: right;
}

.JobRole a:hover {
  background-color: #005b8f;
}

@media only screen and (max-width: 768px) {
  .JobRole {
    width: 48%;
  }
}

@media only screen and (max-width: 480px) {
  .JobRole {
    width: 100%;
  }
}
