body {
  background-color: #ffffff; /* Set your desired background color */
}

/* Main container styles */
.services-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  min-height: 100vh; /* Ensure the container takes at least the full viewport height */
  background-color: rgba(255, 255, 255, 0.9); /* Add a semi-transparent white background */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); /* Add a subtle box shadow */
}

.service-header{
  font-family: Copperplate Gothic;
}

.service-title-1{
  font-family: Garamond;
  font-weight: 600;
}

.service-description{
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.services {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
}

.service-card {
  flex: 0 0 450px;
  margin: 15px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.service-card img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
}

.service-card h3 {
  margin: 10px;
}

.service-card p {
  font-size: 20px;
  line-height: 1.5;
  color: #333333;
  margin: 10px;
  flex-grow: 1;
  text-align: center;
}


.service-card a {
  display: block;
  text-align: center;
  background-color:  #3d52a0;
  color: white;
  padding: 12px 16px;
  text-decoration: none;
  border-radius: 8px;
  margin-top: 16px;
  font-weight: bold;
}

.service-card a:hover {
  background-color: #8697C4;
}

@media screen and (max-width: 520px) {
  .service-card {
    flex: 1 1 450px;
  }
}
