.Careers {
    width: 100%;
    margin: 0 auto;
    max-width: 1200px;
    padding: 20px;
    font-family: 'Garamond';
  }
  
  .Careers h1 {
    font-size: 36px;
    margin-bottom: 20px;
  }
  
  .JobRoles {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  
  @media only screen and (max-width: 600px) {
    .JobRoles {
      flex-direction: column;
      align-items: center;
    }
  }
  